#__next {
    margin: 0 auto;
}

.app {
    margin: 20px 30px;
}

.Address-modal {
    max-width: 600px;
}

.Quote-group {
    background: #f9f9f9;
    border-radius: 6px;
}

.Appbar-link {
    width: 212px;
    height: 33px;
    margin-left: 10px;
    margin-right: 20px;
    display: flex;
}

.Appbar-svg {
    height: 100%;
    width: 100%;
}

.Appbar-nav {
    display: flex;
}

.Appbar-nav .Appbar-navLink {
    color: #34dfe8;
    font-size: 15px;
    display: block;
    padding: 0 8px;
    height: 64px;
    line-height: 64px;
}

.Appbar-nav .Appbar-navLink:hover {
    background: rgba(255, 255, 255, 0.1);
    text-decoration: none;
}

.Appbar-newQuote {
    margin: 0 20px;
}

.Appbar-navLink {
    padding: 0 8px;
}

.Appbar-search {
    flex-grow: 1;
    align-items: center;
    margin-left: 21px;
    margin-right: 24px;
}

.App-footer {
    font-size: 12px;
    padding: 25px 45px;
    text-align: center;
}

.MuiFormControl-root[class*='Address-formControl'] {
    display: flex;
}

.Address-formGroup {
    padding-top: 25px;
}

.MuiFormControlLabel-root[class*='Address-choice'] {
    border: 2px solid #ecf4f7;
    border-radius: 6px;
    padding: 21px 10px 25px 21px;
    margin-right: 0;
    margin-bottom: 25px;
    align-items: flex-start;
}

.MuiFormControlLabel-root[class*='Address-selected'] {
    border-color: #106c92;
}

.MuiIconButton-colorPrimary {
    color: #106c92;
}

.MuiIconButton-colorPrimary:hover {
    color: #106c92;
    background-color: #106c9233;
}

.MuiTab-root {
    min-width: 100px;
}

.PrivateTabIndicator-colorSecondary-10 {
    background-color: #fdd124;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: black;
    font-weight: bold;
}

.MuiTab-textColorPrimary {
    color: #106c92;
    text-transform: none;
}

.MuiDialogTitle-root,
.MuiDialogTitle-root h2 {
    font-family: 'Proxima Nova';
    font-weight: bold;
    font-size: 24px;
}

.Appbar-account {
    height: 64px;
}

.Appbar-accountLinks {
    display: flex;
    align-items: center;
}

.Appbar-accountLinks .MuiSvgIcon-root {
    margin-right: 6px;
}

.App-submitBtn .MuiButton-label {
    font-size: 22px;
    justify-content: space-between;
    font-weight: bold;
    line-height: 1.78;
}

.App-submitBtn.App-loginBtn {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #c02418;
    color: #fff;
    margin-top: 20px;
}

.App-submitBtn.App-loginBtn:hover,
.App-submitBtn.App-loginBtn:active,
.App-submitBtn.App-loginBtn:focus {
    background-color: #c02418;
    color: #fefafa;
}

.App-submitBtn .MuiButton-iconSizeLarge > *:first-child {
    font-size: 32px;
}

.App-proceedFloodpro {
    background: #f7fafb;
}

.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 99999;
}

.Right-Button {
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.Secondary-Right-Button {
    position: fixed;
    right: 275px;
    bottom: 10px;
}

.navBar-subtext {
    padding-top: 10px;
    font: 14px Proxima Nova;
    color: grey;
}

.actionDiv {
    position: fixed;
    color: #106c92;
}

.actionIcon {
    text-align: center;
}

.actionLabel {
    text-align: center;
    font: 14px Proxima Nova;
}

.rightRail-skeleton {
    margin-top: 10px;
    margin-bottom: 30px;
}

html {
    scroll-behavior: smooth;
}

:target:before {
    content: '';
    display: block;
    height: 160px;
    margin: -160px 0 0;
}

a:link {
    color: #106c92;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #106c92;
    background-color: transparent;
    text-decoration: none;
}

a:link.App-submitBtn,
a:visited.App-submitBtn {
    color: #141a18;
    background-color: #ffea77;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
