.Progress-list .MuiListItem-root {
    padding-left: 0;
    padding-right: 0;
    color: rgba(0, 0, 0, 0.6);
}

.Progress-list .MuiListItemIcon-root {
    min-width: 32px;
    height: 18px;
}
.Progress-list .MuiSvgIcon-root {
    height: auto;
}

.Progress-list .MuiListItemIcon-root {
    color: #aecdda;
}

.Progress-list .Progress-complete,
.Progress-list .Progress-complete .MuiListItemIcon-root {
    color: #106c92;
}
