.Coverage-toggle {
    box-shadow: 0 1px 1px 0 rgba(75, 138, 255, 0.25);
}

.Coverage-toggle .MuiToggleButton-root {
    border-color: rgba(83, 88, 98, 0.24);
}

.Coverage-toggle .MuiToggleButton-root,
.Coverage-toggle .MuiToggleButton-root .MuiTypography-root {
    color: rgba(0, 0, 0, 0.7);
}

.Coverage-toggle .MuiToggleButton-root:hover {
    background-color: rgba(18, 121, 163, 0.04);
}

.Coverage-toggle .MuiToggleButton-root[class*='Mui-selected'],
.Coverage-toggle .MuiToggleButton-root[class*='Mui-selected']:hover,
.Coverage-toggle .MuiToggleButton-root[class*='Mui-selected']:focus {
    background-color: #17c2cc;
    border-color: #17c2cc;
    font-weight: bold;
}

.Coverage-toggle .MuiToggleButton-root[class*='Mui-focusVisible'] {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.Coverage-toggle .MuiToggleButton-root[class*='Mui-selected'],
.Coverage-toggle
    .MuiToggleButton-root[class*='Mui-selected']
    .MuiTypography-root {
    color: #fff;
    font-weight: bold;
}
