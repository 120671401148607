.Premium-selected .MuiTableCell-root {
    font-weight: bold;
    background: rgba(0, 56, 115, 0.08);
}

.Premium-table {
    /* box-shadow: 0 1px 1px 0 rgba(75, 138, 255, 0.25); */
}

.Premium-table .MuiTableHead-root .MuiTableCell-head {
    color: #6c6c84;
}

.Premium-table .MuiTableBody-root .MuiTableCell-root {
    font-size: 16px;
}

.Premium-table .MuiTableBody-root .MuiTableCell-root:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.Premium-table .MuiTableBody-root .MuiTableCell-root:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.Premium-table
    .MuiTableBody-root
    .MuiTableRow-root:not([class*='Premium-selected']):not([class*='Read-only']):hover
    .MuiTableCell-root {
    background: rgba(0, 98, 208, 0.08);
}
.Premium-table
    .MuiTableBody-root
    .MuiTableRow-root:not([class*='Premium-selected'])
    .MuiTableCell-root {
    cursor: pointer;
}

.Premium-table .MuiTableCell-root {
    width: calc((100% / 3) - 12px);
}

.Premium-table .MuiTableCell-root:first-child {
    width: 30px;
    padding-right: 0;
}
