.RiskScore-speedometer {
    width: 71px;
    height: 33px;
    position: relative;
}
.MuiSvgIcon-root[class*='RiskScore-gauge'] {
    font-size: 12px;
}

.RiskScore-move {
    position: absolute;
    left: calc(50% - 1px);
    bottom: 0px;
    transform: rotate(-90deg);
    height: 0;
    width: 0;
    border: 1px solid red;
}

.RiskScore-gauge {
    position: absolute;
    transform: scale(0.8, 1.87);
    top: -8px;
    left: -6px;
}
.RiskScore-gauge path {
    fill: #000;
}

.RiskScore-gaugeCell {
    width: 71px;
    border-bottom: 0;
}

.RiskScore-table {
    border-radius: 6px;
    overflow: hidden;
}

.MuiPaper-root[class*='RiskScore-paper'] {
    background-color: #f6f8fa;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

.RiskScore-table .MuiTableBody-root .MuiTableCell-root {
    font-size: 1.1em;
    border-bottom: 1px solid #ebeff4;
    border-top: 1px solid #ebeff4;
}

.MuiTableCell-root[class*='RiskScore-gaugeCell'] {
    border-bottom: 0;
    border-top: 1px solid transparent !important;
    border-right: 1px solid #ebeff4;
    background: transparent;
}

.RiskScore-table
    .MuiTableBody-root
    .MuiTableCell-root:not([class*='RiskScore-gaugeCell']) {
    background-color: #ebeff4;
}

.RiskScore-table
    .MuiTableHead-root
    .MuiTableCell-root:not([class*='RiskScore-gaugeCell']) {
    border-bottom: 1px solid #ebeff4;
}

.RiskScore-table .MuiTableHead-root .MuiTableCell-head {
    line-height: 1.45em;
    font-size: 1em;
    color: #6c6c84;
}

.Riskscore-over100 {
    font-size: 0.62em;
}
